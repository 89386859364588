.teamRow {
    border: none;
    margin: 1rem 1rem 4rem 1rem !important;

}

.teamRow h1 {

    margin-bottom: 3rem;
}

.cardRow {
    border: none;
    margin-bottom: 3rem;
}

.mobileTeam {
    display: none;
}

@media screen and (max-width: 650px ) {

    .cardRow {
        display: none !important;
    }

    .mobileTeam {
        display: contents;
    }

    /* The heart of the matter */
    .testimonial-group > .mobilerow {
        overflow-x: auto;
        white-space: nowrap;
    }
    .testimonial-group > .mobilerow > .col-xs-4 {
        display: inline-block;
        float: none;
    }
    
    .teamRow h5 {
        font-size: 14px !important;
    }

    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: url('http://cdn.css-tricks.com/wp-content/themes/CSS-Tricks-10/images/header-demos.jpg');
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
      }

      .reveal{
        transform: translateY(0);
        transition: none;
        opacity: 1;
      }
      
      .reveal.active{
        transform: translateY(0);
      }
    
}