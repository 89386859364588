

.navbar-custom {
    background: url('../../Images/navbarRibbon.png');
    padding: 0;
    margin: 0;
    font-size: xx-large;
    height: 49px;
    margin-top: 0.3rem;

}

.link {
    padding: 0;
    margin-top: 5px;
}

.duel__nav-socials_container {
    margin-right: 1rem;
    flex-wrap: nowrap;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
}

.duel__nav-socials_container img {
    margin-right: 3rem;
    flex-wrap: nowrap;
    padding: 0;
}

.logo {
    position: absolute; 
    z-index:1; 
    width: 121px;
    height: 117px;
    margin-top: 2rem;
}




@font-face {
    font-family: Exorcist;
    src: url("../../Fonts/Exorcist.otf") format("opentype");
  }

  @media screen and (max-width:1080px) {
    .navbar-custom {
        font-size: x-large;
    }

    .etherscan {
        margin: '9px 7px 0px 0px';
    }

    .duel__nav-socials_container img {
        margin-bottom: 0px !important;
    }

    
  }

  @media screen and (max-width:990px) {

    .duel__nav-socials_container img {
        margin-bottom: 4px !important;
    }

    .link {
        padding: 0;
        margin-top: 0px;
    }   

    
  }

  @media screen and (max-width:650px) {

    .navbar-custom {
        font-size: large;
    }

    .link {
        padding: 0;
        margin-top: 4px;
    }  
    
    .duel__nav-socials_container img {
        margin-bottom: 4px !important;
    }

    
  }

  @media screen and (max-width: 580px) {
    .navbar-custom {
        display: none;
    }




    
  }

