.bg-image{
    background-image: linear-gradient(
        to bottom,
        rgb(0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0) 50%,
        rgb(0, 0, 0) 100%), url('../../Images/faqBG.png');
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 6rem;
    background-position: 0px 70px;
}

.faq {
    height: 100%;
    padding-bottom: 2rem;
}

.accordion-button {
    background-color: #FFC41F !important;
    border: 1px solid black;
  }


.accordion-button:not(.collapsed) {
    color: #212529;
  }

  .accordion-button::after {
	background-image: url("../../Images/sword.png") !important;
}



.discordButton {
    background-image: url('../../Images/walletButton.png');
    border: none;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: cover;
}


@font-face {
    font-family: Exorcist;
    src: url("../../Fonts/Exorcist.otf") format("opentype");
  }


  @media screen and (max-width: 1050px ) {
    .accordion {
        margin: 6rem 0 1rem 0 !important;
    }

    
}

@media screen and (max-width: 760px ) {
    .col-7 {
        padding: 2rem 2rem !important;
        margin-top: 0;
        width: 100vw !important;
        font-size: 32px;
    }

    .bg-image{
        background-position: 0px 80px;
        background-image: url('../../Images/faqBG.png');
        background-size: contain;
    }
    
    .accordion {
        margin-top: 9rem !important;
      }
    
    
}
