.duel__partners {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* background: url('../../Images/logoBackground.png'); */
    margin-top: 3rem;
  }



  .duel__partners-content {
    flex:1;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;

  }


  .duel__partnerslogo-content {
    flex:1;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    border-top: 1px solid #CA8D18;
    border-bottom: 1px solid #CA8D18;
    flex-wrap: nowrap;

  }


  .images img {
    margin-right: 6rem;
    width: 320px;
    height: 447px;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }


  .logos img {
    max-width:100%;
    max-height:100%;
    margin-right: 9rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }



  

  .duel__partners-content_mobile {
    display: none;
  }


  @media screen and (max-width: 1450px) {
    .duel__partners{
        align-content: center;
        flex-direction: column;
    }


    .duel__partners-content {
        width: 100%;
    }
}

@media screen and (max-width: 650px) {
    

    .images img {
        margin-right: 3rem;
        width: 180px;
        height: 247px;
        margin-top: 3rem;
        margin-bottom: 3rem;
      }
    
    
      .logos img {
        width:70px;
        height:57px;
        margin-right: 7.5rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }

      .duel__partners {
        
        margin-bottom: 8rem;
        margin-top : 6rem;
      }
    

}
