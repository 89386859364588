

.duel__header {
    display: flex;
    background-image: linear-gradient(transparent, transparent, transparent, black 99.9%), url('../../Images/HomeImage.gif') ;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #1b1f23;
    width: 100%;
    height: 1380px;
    flex-direction: column;
}

@font-face {
    font-family: Exorcist;
    src: url("../../Fonts/Exorcist.otf") format("opentype");
  }
  
  

.btn-custom {
    background-image: url('../../Images/walletButton.png')  !important;
    border: none;
    cursor: pointer;
    font-family: 'Exorcist';
    color: black;
    background-repeat: no-repeat;
    background-position: 0px -4px;
    background-size: cover;
    width:190px !important;
    padding: 0;
    font-size: medium !important;
    height: 47px !important;

}



.duel__home-button_container {
    margin-left: auto; 
    margin-right: 3rem;
    margin-top: 2rem;
    flex-wrap: nowrap;
}


.duel__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 40rem;
    margin-left: 3rem;
    position: relative;
}



.duel__header-content_button {
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-family: 'Quantico';
    justify-content: center;
    text-align: center;
    margin-bottom: 3rem;
    padding: 0.5rem 4rem;
    background: linear-gradient(180deg, rgba(233,158,63,1) 0%, 
    rgba(220,138,62,1) 35%, rgba(207,117,58,1) 100%);
    box-shadow: 0px 0px 3px 0px #eea61b, 0px 0px 5px 0px #eea61b,
    0px 0px 19px 0px #eea61b, 0px 0px 38px 0px #eea61b, 0px 0px 65px 0px #eea61b,
    0px 0px 113px 0px #eea61b;
    color: black;

}



.duel__header-content_button :hover{
    color: white;
 }

 .duel__header-content_container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    border: none !important;
    margin-top: 3rem !important;
    width: 100% !important;
}



.duel__header-content h1 {
    font-size: 68px;
    font-family: 'Exorcist';
    font-weight: 400;
    color: #ffffff;
    align-self: center;
}

.duel__header-content h2 {
    font-size:24px;
    font-family: 'Quantico';
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    align-self: center;
    padding-bottom: 20px;
}

.duel__header-content p {
    font-size:38px;
    font-family: 'Quantico';
    font-weight: 400;
    color: #eea518;
    text-align: center;
    align-self: center;
}


.duel__header-content_container h2 {
    font-size: 24px;
    font-family: 'Quantico';
    font-weight: 400;
    color: #eea518;
    align-self: center;

}

.duel__header-content_container p {
    font-size: 24px;
    font-family: 'Quantico';
    font-weight: 400;
    color: white;
  
}

.mobileNav {
    display: none;
}



@media screen and (max-width: 1350px) {
    .duel__header {
        flex-direction: column;
        align-content: center;
    }


    .duel__header-content {
        text-align: center;
        margin-top: 45rem;
        margin-left: 0;
    }

    .duel__navbar-mobile {
        display: flex;
        border-bottom: 5px solid #eea518;

    }

    .duel__header-content_container {
        align-content: center;
        justify-content: center;
    }

    .duel__home-socials_container {
        margin-top: 1rem;
    }

}

@media screen and (max-width: 650px) {
    .duel__header Text {
        font-size: 48px;
        line-height: 60px;
    }


    .duel__header-content h1 {
        font-size: 32px;
        font-weight: 400;
        color: #ffffff;
        align-self: center;
        padding-bottom: 20px;
    }
    
    .duel__header-content h2 {
        font-size:16px;
        font-weight: 400;
        color: #ffffff;
        align-self: center;
        padding-bottom: 20px;
    }
    
    .duel__header-content p {
        font-size:16px;
        font-family: 'Quantico';
        font-weight: 400;
        color: #eea518;
        align-self: center;
    }


    .duel__header-content_button {
        border-radius: 10px;
        border: none;
        cursor: pointer;
        font-family: 'Quantico';
        justify-content: center;
        flex-wrap: nowrap;
        text-align: center;
        padding: 0.5rem 4rem;
        color: black;

    }

    .duel__header-content_container h2 {
        font-size: 16px;
        font-family: 'Quantico';
        font-weight: 400;
        color: #eea518;
        align-self: center;
    }
    
    .duel__header-content_container p {
        font-size: 16px;
        font-family: 'Quantico';
        font-weight: 400;
        color: #ee4b17;
      
    }

    
}

@media screen and (max-width: 580px) {
        
    .duel__header {
        display: flex;
        background-image: linear-gradient(transparent, transparent, transparent, black 99.9%), url('../../Images/HomeImage.gif') ;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #1b1f23;
        background-size: contain;
        height: 390px;

    }

    .duel__header-content {
        margin-top: 8rem;
    }

    .duel__header-content h1 {
        display: none;
    }

    .duel__header-content_container {
        margin-top: 3rem !important;

    }


    .duel__header-content_container h2 {
        font-size: 10px;
    }
    
    .duel__header-content_container p {
        font-size: 10px;
      
    }

    .duel__home-button_container {
        display: none;
      
    }

    .mobileNav {
        display: contents;

    }

    .customCol-1 {
        margin-left: 2rem !important;
    }

    .customCol-2 {
        margin-left: 11rem !important;
    }

    



}
