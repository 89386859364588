.bg-image2  {
    height: 100vh;
    background-image: linear-gradient(
        to bottom,
        rgb(0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0) 50%,
        rgb(0, 0, 0) 100%), url('../../Images/greencave-1.webp');
        background-repeat: no-repeat;
        background-size: cover;
}

@media screen and (max-width: 780px) {

    .bg-image2  {

        background-repeat: no-repeat;
        background-size: contain;
        height: 281px;
        background-size: auto 281px;
    }

    .h1 {
        font-size: large;
        font-family: 'Exorcist' !important;
        bottom:0 !important;
        text-align:center !important;
        padding: 8rem 0 0 0rem !important;
    }

    .customCol {
        margin-left: 1rem !important;
    }

    .customCol2 {
        margin-left: 11rem !important;
    }
    
}
