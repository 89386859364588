.duel__mobile-nav {
    display: flex;
    background-color: black;
    justify-content: center;
    padding-top: 5rem;
}

.duel__mobile-nav ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.duel__mobile-nav a:hover{
    color: white;
}


.duel__mobile-nav li {
    text-decoration: none;
    font-family: 'Exorcist';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 29px;
    /* identical to box height */
    text-align: center;

    display: flex;
    align-items: center;
    padding: 1.5rem 0;
    padding-right: 2rem;
    text-transform: uppercase;

    color: #FFFFFF;
}

.duel__mobile-button {
    color: white;
    background-color: black;
    cursor: pointer;
    text-align: center;
    padding: 1rem;
    font-family: 'Exorcist';

}


.duel__mobile-button button {
    color: white;
    background-color: black;
    cursor: pointer;
    text-align: center;
    padding: 0.5rem 3rem;
    border-radius: 5px;
    text-transform: uppercase;
    font-size: 30px;
}

.duel__mobile-socials_container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 1.5rem 1rem;

}

.duel__mobile-socials_container button {
    border-radius: 5px;
    padding: 0.5rem;
}
