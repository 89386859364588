.header {
    text-align: center;
    font-size: 96px;

    
}

@font-face {
    font-family: Exorcist;
    src: url("../../Fonts/Exorcist.otf") format("opentype");
  }


.homeBody {
    width: 100%;
    background-color: black;
    z-index: -1;
}




@media screen and (max-width:650px) {

    .header {
        font-size: 48px;
    }
    
}

@media screen and (max-width:450px) {

    .header {
        font-size: 36px;
    }
    
}