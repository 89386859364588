


.row {
    border: 1px solid #FFFFFF;
    border-radius: 15px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-width: 100vw;


}

.reveal{
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 0.5s all ease;
  }
  
  .reveal.active{
    transform: translateY(0);
    opacity: 1;
  }

.bgImage {
    background: url('../../Images/logoBackground.png') !important;
    border-radius: 20px;
}

.column {
    margin-top: 2rem;
}

.card {
    max-width: 100%;
    max-height: 100%;
}

.mobileBody {
    display: none;
}



@font-face {
    font-family: Exorcist;
    src: url("../../Fonts/Exorcist.otf") format("opentype");
  }

  @media screen and (max-width: 980px) {
    .row {
        padding-top: 3rem !important;
    }

  }



  @media screen and (max-width: 580px) {
    .mobileBody {
        display: contents;
        text-decoration: none;
        margin-top: 6rem;
        
    }

    .img {
        border: 1px solid white;
        border-radius: 20px;
      }

      .alice-carousel__dots-item {
        width: 20px !important;
        height: 20px !important;
        background-color: #77520c !important;
      }
      
      .alice-carousel__dots-item.__active {
          background-color: #eea518 !important;
      }


    .hidden {
        display: none;
    }

  }

